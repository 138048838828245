import '../scss/bundle.scss'
import barba from '@barba/core'
import { gsap } from 'gsap'

// Page Transition
barba.init({
	transitions: [
		{
			name: 'default-transition',
			leave() {
				gsap.to('.page-transition', { opacity: 0, duration: 0.5 })
			},
			enter() {
				topFunction()
				gsap.from('.page-transition', { opacity: 0, duration: 0.5 })
			},
		},
	],
})

// Move to top of page
function topFunction() {
	document.body.scrollTop = 0 // For Safari
	document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
}

// Get browser correct height
const vh = window.innerHeight * 0.01
document.documentElement.style.setProperty('--vh', `${vh}px`)

// Navigation
const header = document.querySelector('.js-header')
const navbutton = document.querySelector('.js-nav-button')

navbutton.addEventListener('click', () => {
	if (header.classList.contains('is-open')) {
		header.classList.remove('is-open')
		document.body.classList.remove('lock')
	} else {
		header.classList.add('is-open')
		document.body.classList.add('lock')
	}
})

let lastKnownScrollY = 0
let currentScrollY = 0

const onScroll = () => {
	currentScrollY = window.scrollY
	if (currentScrollY > lastKnownScrollY) {
		header.classList.add('is-scrolled')
	} else if (currentScrollY < lastKnownScrollY) {
		header.classList.remove('is-scrolled')
	}
	lastKnownScrollY = currentScrollY
}

window.addEventListener('scroll', () => {
	onScroll()
})
